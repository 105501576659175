
import { defineComponent} from 'vue';
// Import icons
import Comment from "./../../Icons/Comment.vue";
import CommentFull from "../../Icons/CommentFull.vue";

export default defineComponent({
    components: {
        'comment': Comment,
        'comment-full': CommentFull,
    },
    props: {
        commentData: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            amountOfComments: '',
            subCommentsVisible: false
        };
    },
    mounted() {
        this.getAmountOfComments()
    },
    watch: {
        commentData: {
            handler() {
                this.getAmountOfComments()
            },
            deep: true
        }
    },
    methods: {
        getAmountOfComments() {
            this.amountOfComments = this.commentData.countSubcomments
        },
    },
});
