import { defineComponent, ref } from 'vue';
import CommentArrow from "./../../Icons/CommentArrow.vue";
import axios from "axios";
// Import icons
import Close from "../../Icons/Close.vue";
export default defineComponent({
    components: {
        'comment-arrow': CommentArrow,
        'close': Close,
    },
    props: {
        formOptions: {
            type: Object,
        },
    },
    emits: ["updateList", "resetForm"],
    data() {
        return {
            form: this.formOptions ? this.formOptions : [],
            properties: {},
            textArea: '',
            isError: false,
            errorMessage: '',
            formHeight: '',
            isReset: false,
        };
    },
    setup() {
        const commentForm = ref(null);
        const commentFormTextArea = ref(null);
        return { commentForm, commentFormTextArea };
    },
    mounted() {
        var _a;
        (_a = this.commentForm) === null || _a === void 0 ? void 0 : _a.addEventListener('submit', (e) => {
            e.preventDefault();
            this.textArea.length >= 3 ? this.send() : this.handleError('Sie müssen mindestens drei Zeichen eingeben');
        });
    },
    watch: {
        formOptions: {
            handler() {
                if (this.formOptions && this.commentFormTextArea) {
                    this.commentFormTextArea.focus();
                    if (this.formOptions.eventType === 'editComment') {
                        this.textArea = this.formOptions.comment.text;
                    }
                    else {
                        this.textArea = '';
                    }
                }
            },
            deep: true
        },
        textArea: {
            handler() {
                this.textArea.length > 0 ? this.isReset = true : this.isReset = false;
            }
        }
    },
    methods: {
        send() {
            let formInputs = new FormData(this.$el);
            axios
                .post(this.form.mainActionUri, formInputs)
                .then((response) => {
                this.handleSuccessResponse(response);
            })
                .catch((error) => {
                this.handleErrorResponse(error);
            });
        },
        handleError(error) {
            this.isError = true;
            this.errorMessage = error;
        },
        handleErrorResponse(error) {
            if (error.response) {
                this.isError = true;
                this.properties = error.response.data;
                this.errorMessage = error.message;
            }
            else {
                console.error('Oops an error occurred.');
            }
        },
        handleSuccessResponse(response) {
            if (response && response.data) {
                this.updateList(response.data.comment.uid);
                this.textArea = '';
                this.isError = false;
            }
        },
        updateList(uid) {
            this.$emit("updateList", uid);
            this.textArea = '';
        },
        resetForm() {
            this.$emit("resetForm");
            this.textArea = '';
        }
    },
});
