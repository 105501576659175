
import {defineComponent, inject, onBeforeUnmount, onMounted, ref} from 'vue';
import DsCookies from './DsCookies';
import VueScrollTo from 'vue-scrollto';
import Collapse from "bootstrap/js/dist/collapse";

export default defineComponent({
  props: {
    isCookiePage: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const cookies: DsCookies | undefined = inject('cookies');
    const details = ref<HTMLElement>();

    if (!cookies) {
      return;
    }

    const scrollToDetails = () => {
      VueScrollTo.scrollTo(details.value, 700, {
        container: details.value?.parentElement
      });
    }

    onMounted(() => {
      if (details.value) {
        new Collapse(details.value, {
          toggle: false
        });

        details.value?.addEventListener('shown.bs.collapse', scrollToDetails);
      }
    })

    onBeforeUnmount(() => {
      if (details.value) {
        details.value?.removeEventListener('shown.bs.collapse', scrollToDetails);
      }
    })


    return {
      cookies,
      details,
      props
    };
  }
});
