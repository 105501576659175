
import axios, { AxiosError, AxiosResponse } from 'axios';
import {defineComponent, ref} from 'vue';
import ElPopover from 'element-plus/lib/el-popover';

// Import icons
import HearthEmpty from "../../Icons/HearthEmpty.vue";
import HearthFull from "../../Icons/HearthFull.vue";
import Users from "../../Icons/Users.vue";
import Close from "../../Icons/Close.vue";

export default defineComponent({
    components: {
      'close': Close,
      'el-popover': ElPopover,
      'hearth-empty': HearthEmpty,
      'hearth-full': HearthFull,
      'users': Users,
    },
    props: {
        identifier: {
            type: String,
            default: ''
        },
        analytics: {
            type: Object,
            default: {}
        },
        likeToggleUri: {
            type: String,
            default: ''
        },
        likedUsersUri: {
            type: String,
            default: ''
        },
        analyticsUri: {
            type: String,
            default: ''
        },
        commentData: {
            type: Object,
            default: {}
        },
    },
    emits: ["updateAnalytics"],
    data() {
        return {
            isLoading: true,
            properties: {} as { [key: string]: any },
            uriIdentifier: '&objectIdentifier=',
            size: 5,
            analyticsObject: {},
            isLoadedAllLikedUsers: false,
            likedUsers: [],
            isLiked: false,
            isDisabled: true,
            isVisible: false
        };
    },
    mounted () {
        if (this.analyticsUri?.length && !this.commentData) {
            this.getAnalytics()
        }
        if (this.commentData) {
            this.initCommentData()
        }
    },
    watch: {
        analytics: {
            handler() {
                this.isLiked = this.analytics.likedByCurrentUser
                if (this.commentData.length) {
                    this.initCommentData()
                }
            },
            deep: true
        }
    },
    methods: {
        likeBtnHover(isBtn : Boolean) {
            if (this.likedUsers.length === 0 && this.analytics.likesCount != 0) {
                this.getLikedUsers()
            }
            if (isBtn) {
                this.isVisible = !this.isVisible
            }
        },
        getLikedUsers() {
            if (this.likedUsersUri) {
                axios
                    .get(<string>this.likedUsersUri)
                    .then((response) => (
                        this.likedUsers = Object.values(response.data)
                    ))
                    .catch((error) => {
                        this.handleErrorResponse(error);
                    })
                    .then(() => {
                        this.isLoading = false;
                    });
            }
        },
        loadAllLikedUsers() {
            this.isLoadedAllLikedUsers = true;
        },
        likeBtn () {
            if (this.likeToggleUri) {
                axios
                    .get( <string>this.likeToggleUri )
                    .then((response) => (
                        response.data ? this.isLiked = true : this.isLiked = false,
                        this.analyticsUri ? this.getAnalytics() : this.$emit("updateAnalytics"),
                        this.getLikedUsers(),
                        this.likedUsers = []
                    ))
                    .catch((error) => {
                        this.handleErrorResponse(error);
                    })
            }
        },
        handleErrorResponse(error: AxiosError) {
            if (error.response) {
                this.properties = error.response.data;
            } else {
                console.error('Oops an error occurred.');
            }
        },
        getAnalytics() {
            axios
                .get(<string>this.analyticsUri)
                .then((response) => (
                    this.analyticsObject = response.data,
                    this.isLiked = response.data.likedByCurrentUser
                ))
                .catch((error) => {
                    this.handleErrorResponse(error);
                })

        },
        initCommentData() {
            if (this.commentData) {
                this.analyticsObject = this.commentData
                this.isLiked = this.commentData.likedByCurrentUser
            }
        }
    },
});
