
import { defineComponent} from 'vue';
import UserLikes from "./UserLikes.vue";
import UserSubComments from "./UserSubComments.vue";
// Import icons
import TrashBin from "./../../Icons/TrashBin.vue";
import CommentAnswer from "./../../Icons/CommentAnswer.vue";
import Edit from "./../../Icons/Edit.vue";

export default defineComponent({
    components: {
        'user-likes' : UserLikes,
        'user-sub-comments' : UserSubComments,
        'trash-bin': TrashBin,
        'comment-answer' : CommentAnswer,
        'edit' : Edit,
    },
    emits: ["addSubComment", "deleteComment", "editComment"],
    data() {
        return {
            uriIdentifier: '&objectIdentifier=',
            comments: {},
        };
    },
    props: {
        allComments: {
            type: Object,
            default: {}
        },
        likeAction: {
            type: String,
            default: ''
        },
        likedUsersUri: {
            type: String,
            default: ''
        },
        analyticsUri: {
            type: String,
            default: ''
        },
        user: {
            type: Object,
            default: ''
        },
    },
    mounted() {
        this.initData()
    },
    watch: {
        allComments: {
            handler() {
                this.initData()
            },
            deep: true
        }
    },
    methods: {
        initData() {
          this.comments = this.allComments
        },
        makeAnalyticsUri(data : any) {
            return this.analyticsUri + this.uriIdentifier + data.objectIdentifier
        },
        makeLikeUri(data : any) {
            return this.likeAction + this.uriIdentifier + data.objectIdentifier
        },
        makeLikedUsersUri(data : any) {
            return this.likedUsersUri + this.uriIdentifier + data.objectIdentifier
        },
        addSubComment(comment : Object ) {
            this.$emit("addSubComment",  comment );
        },
        deleteComment(comment : Object) {
            this.$emit("deleteComment",  comment );
        },
        editComment(comment : Object) {
            this.$emit("editComment",  comment );
        },
        isCurrentUser(comment : any) {
            return  this.user.uid === comment.user.uid
        }
    },
});
