import { createI18n } from "vue3-i18n";

const messages = {
    en: {
        cookies: {
            button: {
                acceptAll: 'Accept All',
                confirm: 'Confirm'
            },
            info: {
                disabled: 'Please enable cookies to view this content'
            }
        }
    }
};

export const i18n = createI18n({
    locale: "en",
    messages: messages,
});
