import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-grid d-sm-block text-end" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  class: "collapse info-panel-details",
  id: "cookie-info-box-collapse",
  ref: "details"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (!_ctx.cookies.closed.value || _ctx.props.isCookiePage)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({
           'cookies-info-box': true,
           'info-panel': !_ctx.props.isCookiePage,
           'cookies-info-box--page': _ctx.props.isCookiePage
          })
          }, [
            _renderSlot(_ctx.$slots, "default", {
              accept: (event) => _ctx.cookies.accept(event),
              acceptAll: () => _ctx.cookies.acceptAll(),
              close: () => _ctx.cookies.close(),
              categories: _ctx.cookies.categories
            }),
            _renderSlot(_ctx.$slots, "actions", {
              accept: (event) => _ctx.cookies.accept(event),
              acceptAll: () => _ctx.cookies.acceptAll(),
              close: () => _ctx.cookies.close(),
              categories: _ctx.cookies.categories
            }, () => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-outline-white mb-half",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cookies.accept && _ctx.cookies.accept(...args))),
                  textContent: _toDisplayString(_ctx.$t('cookies.button.confirm'))
                }, null, 8, _hoisted_2),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-white ms-sm-1 mb-half",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cookies.acceptAll && _ctx.cookies.acceptAll(...args))),
                  textContent: _toDisplayString(_ctx.$t('cookies.button.acceptAll'))
                }, null, 8, _hoisted_3)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "details")
            ], 512)
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "reopen", {
              reopen: () => { _ctx.cookies.reopen(); }
            })
          ]))
    ]),
    _: 3
  }))
}