import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-interact-comment" }
const _hoisted_2 = { class: "user-interact-amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comment = _resolveComponent("comment")!
  const _component_comment_full = _resolveComponent("comment-full")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.amountOfComments ? _ctx.amountOfComments : ''), 1),
    _createElementVNode("button", {
      class: "btn btn-icon user-interact-comment-btn user-interact-comment-btn--no-interact",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.subCommentsVisible = !_ctx.subCommentsVisible))
    }, [
      _createVNode(_component_comment),
      _createVNode(_component_comment_full)
    ])
  ]))
}