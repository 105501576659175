
import { defineComponent, ref } from 'vue';

const SCROLL_DELTA = 50;
const HEADER_SCROLLED_OFFSET = 150;

export default defineComponent({
    data() {
        return {
            isSearchOpen: false,
            isScrolled: false,
        }
    },
    setup() {
        let isHidden = ref(false);
        let lastScrollPosition = window.pageYOffset;
        let isScrolled = ref(false);

        window.addEventListener('scroll', () => {
            if (window.pageYOffset >= 0) {
                if (Math.abs(window.pageYOffset - lastScrollPosition) >= SCROLL_DELTA) {
                    isHidden.value = window.pageYOffset >= lastScrollPosition;
                    lastScrollPosition = window.pageYOffset;
                }
            }

            if (window.pageYOffset > HEADER_SCROLLED_OFFSET) {
                if (!isScrolled.value) {
                    isScrolled.value = true;
                }
            } else if (isScrolled.value) {
                isScrolled.value = false;
            }
        });

        return {
            isHidden,
            isScrolled,
        };
    },
    methods: {
        toggleMobileNav() {
            let mobileNav = document.querySelector('.mobile-nav');

            if (mobileNav) {
                document.body.classList.toggle('mobile-nav-opened');
                if (document.body.classList.contains('mobile-nav-opened')) { // opening mobile nav
                    const activeNavItems:Array<HTMLElement> = [];
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('a.active')).forEach((link) => {
                        const activeNavItem = link.parentElement;
                        if (activeNavItem && activeNavItem.classList.contains('sub')) {
                            activeNavItems.push(activeNavItem);
                        }
                    });
                    activeNavItems.forEach((item) => {
                        item.classList.add('js-opened');
                        window.domSlider.slideDown({ element: item.querySelector('nav') });
                    });
                } else { // closing mobile nav
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll('.js-opened')).forEach((item) => {
                        item.classList.remove('js-opened');
                        window.domSlider.slideUp({ element: item.querySelector('nav') });
                    });
                }
            }
        },
        toggleSubNav(event: Event) {
            const navItem = (event.target as HTMLElement).parentElement as HTMLElement;
            if (navItem.classList.contains('sub')) {
                event.preventDefault();
                navItem.classList.toggle('js-opened');
                window.domSlider.slideToggle({ element: navItem.querySelector('nav') });
            }
        },
    }
})
