import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "user-interact-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "user-interact"
}

export function render(_ctx: any,_cache: any) {
  const _component_user_likes = _resolveComponent("user-likes")!
  const _component_user_comments = _resolveComponent("user-comments")!
  const _component_trash = _resolveComponent("trash")!
  const _component_trash_full = _resolveComponent("trash-full")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_user_likes, {
                identifier: _ctx.newsIdentifier,
                "like-toggle-uri": _ctx.makeToggleUri(),
                "liked-users-uri": _ctx.makeLikedUsersUri(),
                analytics: _ctx.analytics,
                onUpdateAnalytics: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getAnalytics()))
              }, null, 8, ["identifier", "like-toggle-uri", "liked-users-uri", "analytics"]),
              _createVNode(_component_user_comments, {
                "news-image": _ctx.newsImage,
                "pop-up-size": _ctx.popUpSize,
                "news-title": _ctx.newsTitle,
                "news-uid": _ctx.newsUid,
                "news-identifier": _ctx.newsIdentifier,
                analytics: _ctx.analytics,
                "default-form-action": _ctx.defaultFormAction,
                "edit-form-action": _ctx.editFormAction,
                "delete-action": _ctx.deleteAction,
                "get-comments-uri": _ctx.getCommentsUri,
                likeAction: _ctx.likeToggleUri,
                "liked-users-uri": _ctx.likedUsersUri,
                "analytics-uri": _ctx.analyticsUri,
                user: _ctx.user,
                onUpdateAnalytics: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getAnalytics()))
              }, null, 8, ["news-image", "pop-up-size", "news-title", "news-uid", "news-identifier", "analytics", "default-form-action", "edit-form-action", "delete-action", "get-comments-uri", "likeAction", "liked-users-uri", "analytics-uri", "user"]),
              (_ctx.newsFrontendUserUid == _ctx.user.uid)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn user-interact-trash-btn p-0 ms-half",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.eraseNews()), ["prevent"]))
                  }, [
                    _createVNode(_component_trash),
                    _createVNode(_component_trash_full)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}