import {App, Plugin} from '@vue/runtime-core';
import DsCookies, { DsCookiesOptions } from './DsCookies';
import CookiesInfoBox from './InfoBox.vue';

// @ts-ignore
const DsCookiesPlugin: Plugin<DsCookiesOptions> = {
  install(app: App, options: DsCookiesOptions) {
    let cookies = new DsCookies(options);

    app.provide('cookies', cookies);
    app.component('CookiesInfoBox', CookiesInfoBox);
  }
}

export default DsCookiesPlugin;
