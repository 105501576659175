import '../Scss/style.scss';

import { createApp, defineAsyncComponent, computed } from 'vue';
import { Component } from '@vue/runtime-core';
import AOS from 'aos';
import axios from "axios";

// @ts-ignore
import { rootComponent } from '@digitalwerk/frontend-muster';
import merge from 'lodash/merge';

// import CookiesInfoBox from './Components/CookiesInfoBox.vue';
import VueDsCookies from './plugins/cookies';
import PageHeader from './Components/PageHeader.vue';

import UserInteract from './Components/UserInteract.vue';

const MD_BREAKPOINT = 768;
const SliderSwiper = defineAsyncComponent(() => import('./Components/SliderSwiper.vue'));
const ImageGallery = defineAsyncComponent(() => import('./Components/ImageGallery.vue'));
const TextWithGallery = defineAsyncComponent(() => import('./Components/TextWithGallery.vue'));
const TimeLine = defineAsyncComponent(() => import('./Components/TimeLine.vue'));

const NewsSelector = defineAsyncComponent(
    () => import('./../../../../dw_page_types/Resources/Private/Vue/News/Selector.vue')
);
const CategorySlider = defineAsyncComponent(
    () => import('./../../../../dw_page_types/Resources/Private/Vue/News/CategorySlider.vue')
);
const LatestNewsSlider = defineAsyncComponent(
    () => import('./../../../../dw_page_types/Resources/Private/Vue/News/LatestNewsSlider.vue')
);

const AjaxForm = defineAsyncComponent(() => import('./Components/AjaxForm.vue'));
const FrontendNews = defineAsyncComponent(() => import('./Components/FrontendNews.vue'));
const NewsList = defineAsyncComponent(
    () => import('./../../../../dw_page_types/Resources/Private/Vue/News/List.vue')
);
const DynamicOffset = defineAsyncComponent(
    () => import('./../../../../dw_page_types/Resources/Private/Vue/News/DynamicOffset.vue')
);

const Dropdown = defineAsyncComponent(() => import('./Components/Dropdown.vue'));
const Modal = defineAsyncComponent(() => import('./Components/Modal.vue'));

const SCROLL_TO_TOP_VISIBILITY_VH_RATIO = 0.75;

// // import * as Sentry from "@sentry/vue";
//
// //TODO: Vue Sentry, DNS must be https
// // Sentry.init({
// //     Vue: Vue,
// //     dsn: "http://4d7eadae930d4b6eae70a46050ff6f4e@195.2.221.3:9000/5",
// // });
// // Vue.config.errorHandler = function (err, vm, info) {
// //     Sentry.captureMessage(err.toString())
// // }
// // Vue.config.warnHandler = function (msg, vm, trace) {
// //     Sentry.captureMessage(msg.toString())
// // }
const projectRootComponent: Component = {
    components: {
        // 'cookies-info-box': CookiesInfoBox,
        'page-header': PageHeader,
        'slider-swiper': SliderSwiper,
        'image-gallery': ImageGallery,
        'text-with-gallery': TextWithGallery,
        'news-selector': NewsSelector,
        'category-slider': CategorySlider,
        'latest-news-slider': LatestNewsSlider,
        'dynamic-offset': DynamicOffset,
        dropdown: Dropdown,
        'frontend-news': FrontendNews,
        'modal-element': Modal,
        'ajax-form': AjaxForm,
        'news-list': NewsList,
        'timeline': TimeLine,
    },
    data() {
        return {
            isScrollToTopVisible: false,
            isDesktop: false,
            user: '',
        }
    },
    provide() {
        return {
            lazyLoad: this.lazyLoad,
            scrollOffset: this.scrollOffset,
            user: computed(() => this.user)
        }
    },
    methods: {
        createdHook() {
            this.getIsUser()
            this.handleScrollToTopVisibility();
            this.checkWindowHeight()
        },
        mountedHook() {
            AOS.init({
                duration: 900,
                once: true,
                disable: window.innerWidth < MD_BREAKPOINT,
            });

            // update AOS on image lazy load
            document.body.addEventListener('load', (e: Event) => {
                if ((e.target as HTMLElement).nodeName === 'IMG') {
                    AOS.refresh()
                }
            }, true)

            this.onResize()
            window.addEventListener('resize', this.onResize);
        },
        handleVh() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            window.addEventListener('resize', () => {
                vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
        },
        handleScrollToTopVisibility() {
            window.addEventListener('scroll', () => {
                if (window.pageYOffset >= window.innerHeight * SCROLL_TO_TOP_VISIBILITY_VH_RATIO) {
                    if (!this.isScrollToTopVisible) {
                        this.isScrollToTopVisible = true;
                    }
                } else if (this.isScrollToTopVisible) {
                    this.isScrollToTopVisible = false;
                }
            });
        },
        checkWindowHeight() {
            const root = document.querySelector(':root') as HTMLElement

            if (root) {
                root.style.setProperty('--vh', window.innerHeight/100 + 'px');

                window.addEventListener('resize', () => {
                    root.style.setProperty('--vh', window.innerHeight/100 + 'px');
                })
            }
        },
        onResize() {
            this.isDesktop = window.innerWidth >= 992
        },
        getIsUser() {
            const pageElement = document.getElementById('page');
            const isUserUri = pageElement ? pageElement.getAttribute('data-is-user') : ''

            if (isUserUri) {
                axios
                    .get(isUserUri)
                    .then((response) => (
                        this.user = response.data
                    ))
            }
        },
    },
};

// remove cookies-info-box from frontend-muster
if ((rootComponent as any).components && (rootComponent as any).components.hasOwnProperty('cookies-info-box')) {
    delete (rootComponent as any).components['cookies-info-box'];
}

const app = createApp(merge(rootComponent, projectRootComponent));

app.component((rootComponent as any).components.PageHeader);
app.component('user-interact', UserInteract )

app.use(VueDsCookies, {
    // 'marketing' includes "Statistik, Analyse und personalisierte Werbung"
    // 'functional' iframes
    categories: ['marketing', 'functional']
});

app.mount('#page');
