import { defineComponent, inject } from 'vue';
import UserComment from './Partial/UserComments.vue';
import UserLikes from './Partial/UserLikes.vue';
import axios from "axios";
import Trash from "../Icons/TrashBin.vue";
import TrashFull from "../Icons/TrashBinFull.vue";
export default defineComponent({
    components: {
        'user-likes': UserLikes,
        'user-comments': UserComment,
        'trash': Trash,
        'trash-full': TrashFull,
    },
    props: {
        newsIdentifier: {
            type: String,
            default: ''
        },
        newsImage: {
            type: String,
            default: ''
        },
        newsTitle: {
            type: String,
            default: ''
        },
        popUpSize: {
            type: String,
            default: ''
        },
        newsUid: {
            type: String,
            default: ''
        },
        likeToggleUri: {
            type: String,
            default: ''
        },
        likedUsersUri: {
            type: String,
            default: ''
        },
        analyticsUri: {
            type: String,
            default: ''
        },
        defaultFormAction: {
            type: String,
            default: ''
        },
        editFormAction: {
            type: String,
            default: ''
        },
        deleteAction: {
            type: String,
            default: ''
        },
        getCommentsUri: {
            type: String,
            default: ''
        },
        newsDeleteUri: {
            type: String,
            default: ''
        },
        newsFrontendUserUid: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            isExecuted: false,
            properties: {},
            uriIdentifier: '&objectIdentifier=',
            analytics: [],
            user: inject('user')
        };
    },
    mounted() {
        this.initUserInteract();
        if (!this.isExecuted) {
            document.addEventListener('scroll', () => {
                this.initUserInteract();
            });
        }
    },
    watch: {
        user: {
            handler() {
                this.getIsUser();
            },
            deep: true
        }
    },
    methods: {
        initUserInteract() {
            if (this.isInViewport(this.$el) && !this.isExecuted) {
                this.isExecuted = true;
                this.getIsUser();
            }
        },
        getAnalytics() {
            const analytics = this.analyticsUri + this.uriIdentifier + this.newsIdentifier;
            if (analytics) {
                axios
                    .get(analytics)
                    .then((response) => (this.analytics = response.data))
                    .catch((error) => {
                    this.handleErrorResponse(error);
                });
            }
        },
        handleErrorResponse(error) {
            if (error.response) {
                this.properties = error.response.data;
            }
            else {
                console.error('Oops an error occurred.');
            }
        },
        makeToggleUri() {
            return this.likeToggleUri + this.uriIdentifier + this.newsIdentifier;
        },
        makeLikedUsersUri() {
            return this.likedUsersUri + this.uriIdentifier + this.newsIdentifier;
        },
        getIsUser() {
            this.user ? this.getAnalytics() : false;
        },
        isInViewport(element) {
            const { top, bottom } = element.getBoundingClientRect();
            const vHeight = (window.innerHeight || document.documentElement.clientHeight);
            return ((top > 0 || bottom > 0) && top < vHeight);
        },
        eraseNews() {
            let text = "Sind Sie sicher, dass Sie den Artikel löschen möchten?";
            if (confirm(text)) {
                const teaser = document.querySelector('.news-teaser-' + this.newsUid);
                if (teaser) {
                    teaser.classList.add('news-teaser--disabled');
                    if (this.newsDeleteUri) {
                        axios
                            .get(this.newsDeleteUri)
                            .then((response) => (this.addSuccessMessage({ data: response.data, teaser: teaser })))
                            .catch((error) => {
                            this.handleErrorResponse(error);
                        });
                    }
                }
            }
        },
        addSuccessMessage({ data, teaser }) {
            if (data) {
                const span = document.createElement("span");
                span.classList.add('news-teaser-message');
                span.innerHTML = "Entfernt, bitte aktualisieren Sie die Seite, wenn Sie Änderungen sehen möchten.";
                teaser.appendChild(span);
            }
        },
    },
});
