import { defineComponent } from 'vue';
import axios from "axios";
import UserCommentForm from './UserCommentForm.vue';
import UserCommentList from './UserCommentList.vue';
import ElDialog from 'element-plus/lib/el-dialog';
// Import icons
import Comment from "../../Icons/Comment.vue";
import CommentFull from "../../Icons/CommentFull.vue";
import HearthEmpty from "../../Icons/HearthEmpty.vue";
import HearthFull from "../../Icons/HearthFull.vue";
export default defineComponent({
    components: {
        'comment': Comment,
        'comment-full': CommentFull,
        'el-dialog': ElDialog,
        'hearth-empty': HearthEmpty,
        'hearth-full': HearthFull,
        'user-comment-form': UserCommentForm,
        'user-comment-list': UserCommentList,
    },
    props: {
        defaultFormAction: {
            type: String,
        },
        editFormAction: {
            type: String,
        },
        deleteAction: {
            type: String,
            default: ''
        },
        newsUid: {
            type: String,
            default: ''
        },
        newsTitle: {
            type: String,
            default: ''
        },
        newsImage: {
            type: String,
            default: ''
        },
        analytics: {
            type: Object,
            default: ''
        },
        popUpSize: {
            type: String,
            default: ''
        },
        getCommentsUri: {
            type: String,
            default: ''
        },
        newsIdentifier: {
            type: String,
            default: ''
        },
        likeAction: {
            type: String,
            default: ''
        },
        likedUsersUri: {
            type: String,
            default: ''
        },
        analyticsUri: {
            type: String,
            default: ''
        },
        user: {
            type: Object,
            default: ''
        },
    },
    emits: ["updateAnalytics"],
    data() {
        return {
            identifierStringForUri: '&objectIdentifier=',
            getCommentsCustomizedUri: '',
            properties: {},
            dialogVisible: false,
            allComments: [],
            htmlResponse: false,
            isLoading: true,
            firstLoad: false,
            formOptions: {
                mainActionUri: '',
                newsUid: this.newsUid,
                commentUid: null,
                isFocused: false,
                comment: [],
                eventType: 'addMainComment',
                isReset: false,
            }
        };
    },
    watch: {
        dialogVisible: {
            handler() {
                this.createCustomAction(this.newsIdentifier ? this.newsIdentifier : false);
                this.createGetCommentsCustomUri(this.newsIdentifier ? this.newsIdentifier : false);
            }
        }
    },
    methods: {
        createCustomEditAction() {
            this.formOptions.mainActionUri = String(this.editFormAction);
        },
        createGetCommentsCustomUri(identifier) {
            this.getCommentsCustomizedUri = this.getCommentsUri + this.identifierStringForUri + identifier;
            this.getComments();
        },
        createCustomAction(identifier) {
            if (identifier) {
                this.formOptions.mainActionUri = this.defaultFormAction + this.identifierStringForUri + identifier;
            }
        },
        getComments(uid = false) {
            if (this.getCommentsCustomizedUri) {
                this.isLoading = true;
                axios
                    .get(this.getCommentsCustomizedUri)
                    .then((response) => (this.allComments = response.data,
                    this.firstLoad = true,
                    uid ? this.scrollTo(700, uid) : ''))
                    .catch((error) => {
                    this.handleErrorResponse(error);
                })
                    .then(() => (this.isLoading = false));
            }
        },
        updateList(uid) {
            if (uid) {
                this.getComments(uid);
            }
            this.$emit("updateAnalytics"),
                this.formOptions.eventType = 'addMainComment';
            this.formOptions.isReset = false;
            this.createCustomAction(this.newsIdentifier ? this.newsIdentifier : false);
        },
        resetForm() {
            this.formOptions.eventType = 'addMainComment';
            this.formOptions.isReset = false;
            this.createCustomAction(this.newsIdentifier ? this.newsIdentifier : false);
        },
        addSubComment(data) {
            this.formOptions.eventType = 'addSubComment';
            this.formOptions.commentUid = data.uid;
            this.formOptions.comment = data;
            this.formOptions.isReset = true;
            this.scrollTo(700, data.uid);
            this.createCustomAction(data.objectIdentifier ? data.objectIdentifier : false);
        },
        editComment(data) {
            this.formOptions.eventType = 'editComment';
            this.formOptions.commentUid = data.uid;
            this.formOptions.comment = data;
            this.formOptions.isReset = true;
            this.createCustomEditAction();
        },
        deleteComment(data) {
            const deleteCommentUri = this.deleteAction + this.identifierStringForUri + data.objectIdentifier;
            axios
                .get(deleteCommentUri)
                .then((response) => (this.getComments(),
                this.$emit("updateAnalytics")))
                .catch((error) => {
            });
        },
        deleteSubComment(data) {
            this.formOptions.eventType = 'deleteSubComment';
        },
        handleErrorResponse(error) {
            if (error.response) {
                this.properties = error.response.data;
            }
            else {
                console.error('Oops an error occurred.');
            }
        },
        scrollTo(delay, uid) {
            setTimeout(() => {
                const dialogBody = document.querySelector('.user-interact-dialog-content');
                const itemString = 'user-interact-comment-item-' + uid;
                const item = document.getElementById(itemString);
                const contentTopOffset = item && dialogBody ? item.offsetTop - dialogBody.clientHeight + item.clientHeight : null;
                if (dialogBody != null && contentTopOffset != null && item) {
                    dialogBody.scroll({
                        top: contentTopOffset,
                        behavior: 'smooth'
                    });
                    this.blink(item);
                }
            }, delay);
        },
        blink(item) {
            item.style.backgroundColor = '#bce4fa';
            setTimeout(() => {
                item.style.backgroundColor = '';
            }, 1000);
        }
    },
});
